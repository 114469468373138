import React, { useState, useEffect } from 'react'
import Layout from '../../../components/Layout'
import ApproveArticle from '../../../components/ApproveArticle'
import { getArticles, updateArticles } from '../../../services/api'
import { navigate } from 'gatsby'
import { getToken, getUserData } from '../../../utils/auth'

const ApproveArticles = () => {
  const user = getUserData()
  const [allArticles, setAllArticles] = useState([])
  let pendingArticles = []
  const [notificationMessage, setNotifcationMessage] = useState({})

  useEffect(() => {
    if (!getToken()) {
      navigate('/auth')
    } else {
      const getAllArticles = async () => {
        try {
          const { data } = await getArticles()
          if (data) {
            setAllArticles(data)
          }
        } catch (err) {
          if (err.message.match(/(403|400)/)) {
            err('You are not a user')
          } else err('somethingWrong')
        }
      }
      getAllArticles()
    }
  }, [])

  if (user) {
    if (user.role.name === 'Editor') {
      if (allArticles) {
        pendingArticles = allArticles.filter(
          (elem) => elem.status === 'Pending'
        )
        const updateArticleStatus = (article) => {
          article.status =
            article.status === 'Pending' ? 'Published' : 'Pending'
        }

        const handleSubmit = () => {
          const sendArticles = async () => {
            try {
              const result = await updateArticles(
                // JSON.stringify(
                pendingArticles.filter((elem) => elem.status === 'Published')
                // )
              )

              setNotifcationMessage({
                body: 'تم الحفظ سيتم نشر المقالات على الموقع ',
                type: 'success',
              })

              return result
            } catch (err) {
              setNotifcationMessage({
                body: 'حدث خطأ. الرجاء المحاولة مجددا.',
                type: 'danger',
              })
            }
          }
          sendArticles()
        }
        return (
          <Layout>
            <div className="has-text-centered">
              {(pendingArticles.length &&
                pendingArticles.map((article) => (
                  <ApproveArticle
                    key={article.id}
                    article={article}
                    updateArticleStatus={updateArticleStatus}
                  />
                ))) || (
                <p className="has-text-centered ">No new articles to approve</p>
              )}
              <div className="has-text-centered">
                {!!pendingArticles.length && (
                  <button className="button is-primary" onClick={handleSubmit}>
                    Submit
                  </button>
                )}
              </div>

              {notificationMessage.body && (
                <div
                  className={`notification is-${
                    notificationMessage.type || 'success'
                  } is-light`}
                >
                  <button
                    className="delete"
                    onClick={() => setNotifcationMessage({})}
                  />
                  {notificationMessage.body}
                </div>
              )}
            </div>
          </Layout>
        )
      }
    }
  } else {
    return <div></div>
  }
}

export default ApproveArticles
