import React, { useState } from 'react'
import PropTypes from 'prop-types'

const ApproveArticle = ({ article, updateArticleStatus }) => {
  const [approveBtnClicked, setapproveBtnClicked] = useState(false)

  const handleClick = () => {
    setapproveBtnClicked(!approveBtnClicked)
    updateArticleStatus(article)
  }
  return (
    <div>
      <div>
        <a href={`/${article.slug}`} target="_blank" rel="noreferrer">
          {article.title}
        </a>
        {/* <Link to={`/${article.slug}`}>{article.title}</Link> */}
      </div>
      <button
        className={`button is-small ${
          approveBtnClicked ? 'is-success' : 'is-light'
        }`}
        onClick={handleClick}
      >
        {approveBtnClicked ? 'approved' : 'approve'}
      </button>
    </div>
  )
}

ApproveArticle.propTypes = {
  updateArticleStatus: PropTypes.func,
  article: PropTypes.object,
}

export default ApproveArticle
